html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.image-placeholder img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.image-label {
  font-size: 16px;
  color: #4a4a4a;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #141b20;
  color: #f9f9f9;
  text-align: left;
  padding: 10px;
  font-size: 16px;
}

.footerimg {
  max-width: 20px;
}

.footerimg-label {
  margin-left: 5px;
  font-weight: lighter;
  font-size: 15px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: row;
    align-items: left;
    text-align: center;
  }

  .footerimg-label {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .image-label {
    font-size: 12px;
  }

  .footer {
    padding: 5px;
  }

  .footerimg {
    max-width: 15px;
  }
}
